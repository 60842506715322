<template>
  <v-container fluid>
    <report-template-form class="pa-4" v-model="payload" @save="save" />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import ReportTemplateForm from "@/components/report/template/ReportTemplateForm.vue";

export default {
  name: "ReportTemplateCreate",

  components: {
    ReportTemplateForm,
  },

  data() {
    return {
      payload: {
        name: "",
        description: "",
        file: null,
        exportType: 0,
      },
    };
  },

  methods: {
    ...mapActions("reports", [
      "updateTemplate",
      "getTemplate",
      "uploadTemplateFile",
    ]),
    ...mapActions("alert", ["success", "error"]),

    async save(values) {
      let templateResult = await this.updateTemplate(values);
      if (!templateResult) return;

      // Upload the file
      if (!values.file) {
        this.success(this.$t("report.template.updateSuccess"));
        await this.$router.push("/report");
        return;
      }

      const uploadPayload = new FormData();
      uploadPayload.append("file", values.file);
      let fileResult = await this.uploadTemplateFile({
        reportSoTemplateId: this.$route.params.id,
        file: uploadPayload,
      });

      if (!fileResult) {
        // Create a error alert
        this.error(this.$t("report.template.uploadFileError"));
        return;
      }

      await this.$router.push("/report");
    },
  },

  async created() {
    if (!this.$route.params.id) this.router.push("/report");

    let template = await this.getTemplate(this.$route.params.id);
    this.payload = template;
  },
};
</script>
