<template>
  <v-form ref="templateForm" @submit.prevent="validateEmit">
    <v-text-field
      v-model="localValue.name"
      :label="$t('report.template.fields.name') + ' *'"
      :rules="nameRules"
      prepend-icon="mdi-text"
    />
    <v-text-field
      v-model="localValue.description"
      :label="$t('report.template.fields.description')"
      prepend-icon="mdi-text"
    />

    <v-select
      v-model="localValue.exportType"
      :items="exportTypes"
      :label="$t('report.template.fields.exportType') + ' *'"
      prepend-icon="mdi-file"
    />

    <v-file-input
      v-model="localValue.file"
      :label="$t('report.template.fields.file')"
      prepend-icon="mdi-file"
    />
    <v-btn type="submit" color="primary" outlined text>{{
      $t("common.save")
    }}</v-btn>
  </v-form>
</template>

<script>
import { ReportExportTypes } from "../../../_helpers/CsharpEnum.js";

export default {
  name: "ReportTemplateForm",

  props: {
    value: Object,
  },

  model: {
    prop: "value",
    event: "input",
  },

  data() {
    return {
      nameRules: [
        (v) =>
          !!v ||
          this.$t("validation.required", [
            this.$t("report.template.fields.name"),
          ]),
        (v) =>
          (v && v.length <= 255) ||
          this.$t("validation.max", [
            this.$t("report.template.fields.name"),
            255,
          ]),
      ],
    };
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    exportTypes() {
      return Object.values(ReportExportTypes).map((value) => ({
        text: this.$t(`report.exportTypes.${value}`),
        value,
      }));
    },
  },

  methods: {
    validateEmit() {
      if (!this.$refs.templateForm.validate()) return;

      this.$emit("save", this.localValue);
    },
  },
};
</script>
